html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.login-page {
    min-height: 100vh;
    overflow-y: auto;
    background: url('./assets/backgroundfinal.webp') no-repeat center center fixed; /* Đảm bảo ảnh nền phủ toàn bộ trang */
    background-size: cover;
}

.login-page .login-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.login-page .content-wrapper {
    text-align: center;
    width: 100%;
}
.login-page .text-center {
    margin-top: 150px;
}
.login-page .logo-img {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
    display: inline-block;
}

.login-page .itb-club-text {
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 64px;
    color: #000;
    margin-bottom: 20px;
    display: inline-block;
}

.login-page .program-name {
    background: #D9D9D9;
    padding: 20px;
    width: 80%;
    margin: 20px auto;
}

.login-page .program-name h2 {
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 48px;
    color: #000;
    margin: 0;
}

.login-page .login-box {
    background: rgba(85, 88, 85, 0.518);
    padding: 40px;
    border-radius: 10px;
    margin: 20px auto;
    width: fit-content;
}

.login-page .login-box h3 {
    font-family: 'Noto Sans Japanese', sans-serif;
    font-size: 64px;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.login-page .google-button {
    margin-top: 20px;
}
/*Footer*/

.login-page .footer {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    padding: 10px 0;
}

/* Sắp xếp cột dọc trên màn hình nhỏ */
.login-page .d-flex {
    display: flex;
    flex-direction: column; /* Sắp xếp cột dọc trên thiết bị di động */
    align-items: center; /* Căn giữa nội dung */
    text-align: center; /* Căn giữa văn bản */
}

.login-page .col-md-4 {
    width: 100%;
    margin-bottom: 20px;
    max-width: 350px;
}

/* Căn giữa văn bản trên điện thoại */
.login-page .text-md-left, .login-page .text-md-right {
    text-align: center !important;
}

.login-page .mb-md-0 {
    margin-bottom: 20px;
}

/* Media queries để điều chỉnh layout trên màn hình lớn hơn */
@media (min-width: 768px) {
    .login-page .d-flex {
        flex-direction: row; /* Sắp xếp ngang trên màn hình lớn */
        justify-content: space-between; /* Căn đều khoảng cách giữa các cột */
    }

    .login-page .col-md-4 {
        width: auto;
        margin-bottom: 0;
        max-width: none;
    }

    .login-page .text-md-left {
        text-align: left !important;
    }

    .login-page .text-md-right {
        text-align: right !important;
    }

    .login-page .mb-md-0 {
        margin-bottom: 0;
    }

    .login-page .col-md-4:nth-child(2) {
        margin-right: 165px; /* Thêm margin-right chỉ trên màn hình lớn */
    }
}

/* Xóa margin-right trên màn hình nhỏ */
@media (max-width: 767px) {
    .login-page .col-md-4:nth-child(2) {
        margin-right: 0 !important; /* Bỏ margin-right trên màn hình nhỏ */
    }
}
