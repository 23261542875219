body {
  margin: 0;
  font-family: system-ui,-apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono" ,'Courier New',
    monospace;
}
.login-box {
  padding: 20px;
  background: rgb(173, 251, 156);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.login-image {
  width: 100%;
  margin-bottom: 20px;
}

.google-button {
  display: block; /* Centering the Google button */
  margin: auto;
}

form {
  background-image: url('./assets/backgroundfinal.webp');
  background-size: cover; /* Ảnh sẽ phủ toàn bộ chiều rộng và giữ tỷ lệ */
  background-position: center center; /* Căn giữa ảnh */
  background-repeat: no-repeat; /* Không lặp lại ảnh */
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  filter: brightness(90%);
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: overlay;
}


/*POP up when error in Login Page*/

.alert {
  padding: 10px 20px !important; /* Điều chỉnh padding để phù hợp với văn bản */
  margin: 15px 0 !important;
  border-radius: 5px !important;
  text-align: center !important; /* Căn giữa văn bản */
  font-size: 14px !important;
  font-weight: bold !important;
}

.alert-danger {
  background-color: #f8d7da !important;
  color: #721c24 !important;
  border-color: #f5c6cb !important;
  padding: 10px 20px !important; /* Điều chỉnh padding để cân bằng */
}

/*POP up when error in Information Page*/

.custom-alert {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px 20px;
  margin: 15px auto;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center; /* Căn giữa nội dung */
  align-items: center;
  position: relative;
  width: 60%;
  box-sizing: border-box;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 1s forwards; /* Hiệu ứng fade-in */
}

@keyframes fadeIn {
to {
  opacity: 1;
}
}

.custom-alert p {
  margin: 0;
}

/*spinner*/
.spinner-overlay {
  position: fixed; /* Đảm bảo spinner luôn xuất hiện ở giữa trang */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Màu nền mờ để tập trung vào spinner */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Đảm bảo spinner nằm trên các phần tử khác */
}

.spinner-border {
  width: 5rem;
  height: 5rem;
  border: 0.3em solid #ffffff;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
      transform: rotate(360deg);
  }
}

/*Notification about applying Doan Khoa, ITB CLUB, LCH, AI4I*/

.notification.show {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Làm mờ nền */
  z-index: 1050; /* Đảm bảo modal hiển thị trên các thành phần khác */
  overflow: hidden; /* Ngăn việc cuộn */
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.notification-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

.notification-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%; /* Ensures responsiveness */
  max-height: 90%; /* Ensures responsiveness */
  overflow: auto; /* Allows content to scroll if too large */
}




